import { DiscussionEmbed } from "disqus-react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { FaRegCalendar, FaRegClock, FaRegFolderOpen } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({ data }) {
  const { markdownRemark: post } = data

  // const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = this.props.pathContext;
  const disqusShortname = "arnestockmans"
  const disqusConfig = {
    identifier: post.id,
    title: post.frontmatter.title,
  }

  const meta = []
  if (post.frontmatter.header != null) {
    const metaImage = `${data.site.siteMetadata.siteUrl}${post.frontmatter.header.publicURL}`
    meta.push({ name: "twitter:card", content: "summary_large_image" })
    meta.push({ name: "twitter:image", content: metaImage })
    meta.push({ name: "og:image", content: metaImage })
  }

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt}
        meta={meta}
      />
      <div className="blog-post-container">
        <div className="blog-post">
          <h1>{post.frontmatter.title}</h1>
          <p className="blog-post-metadata">
            <span style={{ marginRight: "1.25rem" }}>
              <FaRegCalendar style={{ verticalAlign: "text-bottom" }} />
              &nbsp;&nbsp;
              {post.frontmatter.date}
            </span>
            <span style={{ marginRight: "1.25rem" }}>
              <FaRegClock style={{ verticalAlign: "text-bottom" }} />
              &nbsp;&nbsp;
              {post.fields.readingTime.text}
            </span>
            {post.frontmatter.category != null && (
              <span>
                <FaRegFolderOpen style={{ verticalAlign: "text-bottom" }} />
                &nbsp;&nbsp;
                {post.frontmatter.category}
              </span>
            )}
          </p>

          {post.frontmatter.header != null && (
            <Img
              fluid={post.frontmatter.header.childImageSharp.fluid}
              style={{ margin: "0.5rem 0 1.5rem 0" }}
            />
          )}

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>

      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        header {
          publicURL
          childImageSharp {
            fluid(maxWidth: 920, maxHeight: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
      }
      fields {
        readingTime {
          text
        }
      }

      excerpt
    }

    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
